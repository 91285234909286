import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "state-layer" }
const _hoisted_3 = { class: "loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onclick && _ctx.onclick(...args))),
    onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onclick && _ctx.onclick(...args)), ["enter"])),
    class: _normalizeClass(["btn", _ctx.classes]),
    tabindex: "0"
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("span", {
      class: "label-large",
      textContent: _toDisplayString(_ctx.label)
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
        [_vShow, _ctx.loading]
      ])
    ])
  ], 34))
}