import { renderSlot as _renderSlot, vShow as _vShow, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "body" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "dialog-transition" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dialog",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMask && _ctx.onMask(...args)), ["self"]))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["dialog-content", { maskClick: _ctx.clicked }])
              }, [
                _renderSlot(_ctx.$slots, "icon"),
                _withDirectives(_createElementVNode("span", {
                  class: "headline-small",
                  textContent: _toDisplayString(_ctx.title)
                }, null, 8, _hoisted_1), [
                  [_vShow, _ctx.title]
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _withDirectives(_createElementVNode("span", {
                    class: "body-medium",
                    textContent: _toDisplayString(_ctx.description)
                  }, null, 8, _hoisted_3), [
                    [_vShow, _ctx.description]
                  ]),
                  _renderSlot(_ctx.$slots, "body")
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "actions")
                ])
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}